import React from "react";
import { API_BASE_URL } from "../config/connect";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { add } from "../store/cartSlice";
import ViemoreProdSkeleton from "./ViemoreProdSkeleton";
import Nodata from "./Nodata";

function Products({ slicedProducts,loading }) {
  const dispatch = useDispatch();

  const addToCart = (product) => {
    // dispatch an add action
    dispatch(add(product));
  };

  if (loading) {
    return <ViemoreProdSkeleton />;
  } else if (slicedProducts.length === 0) {
    return <Nodata />;
  } else {
    return (
      <>
        {slicedProducts.map((post, index) => (
          <div key={index} className="col-xl-4 col-lg-6 col-md-6 col-sm-8">
            <div className="shop-product-item mb-30">
              <div className="shop-thumb">
                <NavLink to={`/shopdetail/${post.code}`}>
                  <img
                    src={post.previewpicture || post.picture}
                    alt="img"
                    width="250px"
                    height="200px"
                  />
                </NavLink>
                <span>New</span>
              </div>
              <div className="shop-content">
                <h2 className="title">
                  <NavLink to={`/shopdetail/${post.code}`}>{post.name}</NavLink>
                </h2>
                <ul className="tag">
                  <li>
                    Packaging{" "}
                    <NavLink to="vendor-profile.html">
                      {post.packagetype}
                    </NavLink>
                  </li>
                </ul>
                <div className="rating d-flex">
      <div>  
       {Array.from({ length: 5 }, (_, index) => (
        <i key={index} className="fa-solid fa-star" style={{color: index < Math.floor(post.ratingaverage) ? '#ffc107' : '#e4e5e9'}}></i>
      ))}
      </div>
      <div>
      <h6 style={{fontWeight: 'normal',fontSize:'12px',marginLeft: '3px',marginTop: '9px'}}>({post.ratingcount})</h6>
      </div>
    </div>
                <span>Discounted : 20%</span>
                <div className="progress">
                  <div
                    className="progress-bar w-50"
                    role="progressbar"
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div className="content-bottom">
                  <h4 className="price">Gh₵ {post.price}</h4>
                  <p
                    // onClick={() => addToCart(post)}
                    style={{ cursor: "pointer", padding: "5px 10px" }}
                  >
                    {/* <span>Add To Cart</span> */}
                  </p>
                </div>
              </div>
            </div>
          </div>
     ))}
     </>
   );
        }}

export default Products;
