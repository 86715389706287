import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../config/connect";
 
const Ingredients = ({ onIngredientsSelected }) => {
  const [SelectedIngredients, setSelectedIngredients] = useState(null);
  const [variations, setVariations] = useState([]);
  const { prodcode } = useParams();

  const getAllProductVariations = async () => {
    try {
      const response = await fetch(
        `https://ghmarkets.net/test/ghmarketconnect/?ghw=productservices&svg=fashionvariations&prcode=${prodcode}`
      );
      const json = await response.json();
      const firstResult = json["results"][0];
      if (firstResult && firstResult.ingredients) {
        const ingredientsObject = firstResult.ingredients;
        if (ingredientsObject.trim() !== "") {
          setVariations(ingredientsObject.split(","));
        } else {
        }
      } else {
      }
    } catch (error) {
      // console.error("Error fetching product variations:", error);
      // Handle error here
    }
  };

  useEffect(() => {
    getAllProductVariations();
  }, [prodcode]);

  //get variation selected and send it to the cart
  const handleColorClick = (size) => {
    setSelectedIngredients(size);
    onIngredientsSelected(size);
  };

  return variations.length > 0 ? (
    <div
      className="sizes-container"
      style={{ display: "flex", flexDirection: "row" }}
    >
      <span style={{ fontSize:'',fontWeight:'bold',color:'black',marginTop:'12px'}}>Ingredients:</span>
      <div className="sizes">
        {variations.map((variation, i) => (
          <div
            key={i}
            className={`size ${SelectedIngredients === variation ? "selected" : ""}`}
            onClick={() => handleColorClick(variation)}
          >
            {variation}
          </div>
        ))}
      </div>
  
    </div>
  ) : (
    ""
  );
};

export default Ingredients;
