import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../config/connect";

const Feature = ({ onFeatureSelected }) => {
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [variations, setVariations] = useState([]);
  const { prodcode } = useParams();

  const getAllProductVariations = async () => {
    try {
      const response = await fetch(
        `https://ghmarkets.net/test/ghmarketconnect/?ghw=productservices&svg=fashionvariations&prcode=${prodcode}`
      );
      const json = await response.json();
      const firstResult = json["results"][0];
      if (firstResult && firstResult.feature) {
        const featureObject = firstResult.feature;
        if (featureObject.trim() !== "") {
          setVariations(featureObject.split(","));
        }
      }
    } catch (error) {
      // Handle error here
    }
  };

  useEffect(() => {
    getAllProductVariations();
  }, [prodcode]);

  const handleFeatureClick = (feature) => {
    setSelectedFeature(feature);
    onFeatureSelected(feature);
  };

  return variations.length > 0 ? (
    <div className="sizes-container" style={{ display: "flex", flexDirection: "row" }}>
      <span style={{ fontSize: '', fontWeight: 'bold', color: 'black', marginTop: '12px' }}>Feature:</span>
      <div className="sizes">
        {variations.map((variation, i) => (
          <div
            key={i}
            className={`size ${selectedFeature === variation ? "selected" : ""}`}
            onClick={() => handleFeatureClick(variation)}
          >
           
           {variation}
          </div>
        ))}
      </div>
    </div>
  ) : (
    ""
  );
};

export default Feature;
