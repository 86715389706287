import React from "react";
import { NavLink } from "react-router-dom";
import discount_shape from "../assets/img/images/discount_shape.png";

function Navbarr() {
    return (
			<div>
				<div className='navbar-wrap main-menu'>
					<ul className='navigation'>
						<li className='active menu-item-has-children'>
							<NavLink to='/'><i className="fas fa-home"></i></NavLink>{' '}
						</li>{' '}
						<li className='menu-item-has-children'>
							<NavLink to=''> SERVICES </NavLink>{' '}
							<ul className='submenu'>
								<li>
									<NavLink to='/profservices'> Professional Services </NavLink>{' '}
								</li>{' '}
								<li>
									<NavLink to='/bluecollar'> Artisans Services </NavLink>{' '}
								</li>{' '}
							</ul>{' '}
						</li>{' '}
						{/* <li className='menu-item-has-children'>
							<NavLink to='/commodities'> AGRIC COMMODITIES </NavLink>{' '}
						</li>{' '} */}
						<li>
							<NavLink to='/productslist'> BIDS </NavLink>{' '}
						</li>{' '}
						{/* <li>
							<a href='index-4.html'> SPECIAL OFFERS </a>{' '}
						</li>{' '} */}
                        
                        <li>
                        <NavLink to="/joinlivemarkets">
                           Live Market
                            <img src={discount_shape} alt="discount_shape"  style={{width:'18px'}}/>
                          </NavLink>
						</li>{' '}
                     
					</ul>{' '}
                    
				</div>{' '}
			</div>
		);
}

export default Navbarr;