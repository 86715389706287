import React from "react";
import { NavLink } from "react-router-dom";
import ViemoreProdSkeleton from "./ViemoreProdSkeleton";
import Nodata from "./Nodata";

function Bluecollarsing({ slicedProducts, loading }) {
  if (loading) {
    return <ViemoreProdSkeleton />;
  } else if (slicedProducts.length === 0) {
    return <Nodata />;
  } else {
    return (
      <>
        {slicedProducts.map((post, index) => (
          <div key={index} className="col-xl-4 col-lg-6 col-md-6 col-sm-8">
            <div className="shop-product-item mb-30">
              <div className="shop-thumb">
                <NavLink to={`/shopdetail/${post.code}`}>
                  <img
                    src={post.previewpicture || post.picture}
                    alt="img"
                    width="250px"
                    height="200px"
                  />
                </NavLink>
                <span>New</span>
              </div>
              <div className="shop-content">
                <h2 className="title">
                  <NavLink to={`/shopdetail/${post.code}`}>{post.name}</NavLink>
                </h2>
                <ul className="tag">
                  <li>
                    Packaging <NavLink to="">{post.packagetype}</NavLink>
                  </li>
                </ul>
                <div className="rating">
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                </div>
                <span>Discounted : 20%</span>
                <div className="progress">
                  <div
                    className="progress-bar w-50"
                    role="progressbar"
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div className="content-bottom">
                  <h4 className="price">Ghs {post.price}</h4>
                  <p>
                    {" "}
                    <span>Add To Cart</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  }
}

export default Bluecollarsing;
