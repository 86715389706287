import React, { useEffect, useState, useRef } from "react";
import { NavLink, useParams } from "react-router-dom";
import { API_BASE_URL } from "../config/connect";
import Header from "../components/Header";
import momo from "../assets/img/payment/momo.webp";
import vodacash from "../assets/img/payment/vodacash.png";
import atmoney from "../assets/img/payment/atmoney.png";
import cards from "../assets/img/payment/cards.png";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
// import 'react-toastify/dist/ReactToastify.css';
import validator from "validator";
import ReCAPTCHA from "react-google-recaptcha";

const RequestService = () => {
  const recaptcha = useRef();
  const notify = (msg) => toast.success(msg);
  const notifyError = (msg) => toast.error(msg);
  const { prodcode } = useParams();
  // const [inputs, setInputs] = useState({});
  const [sellercode, setSellerCode] = useState("");
  const [servicecode, setServiceCode] = useState("");
  const [servicename, setServiceName] = useState("");
  const [description, setDescription] = useState("");
  const [quote, setQuote] = useState("");
  const [processstatus, setProcessStatus] = useState("");
  const [paymentstatus, setPaymentStatus] = useState("");
  const [mobilemoneycontact, setMobileMoneyContact] = useState("");
  const [inputs, setInputs] = useState({
    prodcode: prodcode,
    firstname: "",
    lastname: "",
    phone_number: "",
    email: "",
    address: "",
    city: "",
    region: "",
    description: "",
    // confirmemail:"",
  });
  const [loading, setLoading] = useState(false);
  const [paymentlink, setPaylink] = useState("");

  const [post, setPost] = useState(null);
  const [toggleDisplay, setToggleDisplay] = useState("none");
  const [message, setMessage] = useState("");
  const [emailconfirmmsg, setConfirmMessage] = useState("");
  const [validinput, setValidinput] = useState(true);
  const [actualmail, setActualmail] = useState("");
  const [username, setUsername] = useState("");
  const [confirmEmail, setConfirmEmail] = useState(""); // Separate state for confirm email
  const [totalAmount, setTotalAmount] = useState(0);
  const [taxAmt, setTaxAmt] = useState(0);
  const Swal = require("sweetalert2");

  const handleDate = () => {
    //disable dates before today
    // const name = event.target.name;

    var dtToday = new Date();

    var month = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();
    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();

    var maxDate = year + "-" + month + "-" + day;
    // alert(maxDate);
    document.getElementById("startdate").setAttribute("min", maxDate);

    var DtToday = new Date();

    var Month = DtToday.getMonth() + 1;
    var Day = DtToday.getDate();
    var Year = DtToday.getFullYear();
    if (Month < 10) Month = "0" + Month.toString();
    if (Day < 10) Day = "0" + Day.toString();

    var MaxDate = Year + "-" + Month + "-" + Day;
    // alert(maxDate);
    document.getElementById("enddate").setAttribute("min", MaxDate);
  };

  const getTaxes = async () => {
    const res = await fetch(`https://ghmarkets.net/test/ghmarketconnect/?ghw=processpayment&svg=gettaxes`);
    const response = await res.json();
    return response;
  };

  const taxes = getTaxes();
  taxes.then((js) => {
    let totalamt = 0 ;
    setTaxAmt(((js["data"] * quote) / 100).toFixed(2));
    if(taxAmt){
    totalamt = parseFloat(taxAmt) + parseFloat(quote) ;
    }else{  totalamt = parseFloat(quote) ; }
    setTotalAmount(totalamt);
  });

  // Update handleEmail
  const handleEmail = (event) => {
    const emailValidity = event.target.value;
    setConfirmEmail(emailValidity); // Update confirm email state
    if (inputs.email !== emailValidity) {
      setConfirmMessage("Email validation....Not yet validated");
      setValidinput(false);
    } else {
      setConfirmMessage("");
      setValidinput(true);
    }
  };


  useEffect(() => {
    if (!quote) {
      handleDate();
    }

    getDetails();
    if (paymentlink) {
      const timeout = setTimeout(() => {
        window.location.assign(paymentlink);
      }, 2000);
      return () => clearTimeout(timeout);
    }
    // Check if user is already logged in and get username
    const storedUsername = localStorage.getItem("username");
    if (storedUsername) {
      setUsername(storedUsername);
      getCustomerDetails();
    }

  }, [sellercode, servicecode, servicename, description, paymentlink]);

  const getDetails = () => {
    fetch(`https://ghmarkets.net/test/ghmarketconnect/?ghw=requestservice&svg=det&prcode=${prodcode}`)
      .then((response) => response.json())
      .then((json) => {
        let scode = json["results"][0].sellercode;
        let servicecode = json["results"][0].code;
        let serviceName = json["results"][0].name;
        // let sdescription = json["results"][0].description;
        let quote = json["results"][0].price;
        let processstatus = "0";
        let paymentstatus = "0";
        setSellerCode(scode);
        setServiceCode(servicecode);
        setServiceName(serviceName);
        // setDescription(sdescription);
        setQuote(quote);
        setProcessStatus(processstatus);
        setPaymentStatus(paymentstatus);
      });
  };

  //api call
  const postData = () => {
    setLoading(true);
    var payload = {
      ...inputs,
      sellercode,
      servicecode,
      servicename,
      quote,
      processstatus,
      paymentstatus,
      taxAmt,
    };
    axios
      .post(
        `https://ghmarkets.net/test/ghmarketconnect/?ghw=requestservice&svg=req&prcode=${prodcode}`,
        JSON.stringify(payload)
      )
      .then((res) => {
        // console.log(res);
        //console.log(res.data);
        // console.log(res.data['data']);
        // console.log(res.data['data']['paylink']);

        const paylink = res.data["data"]["paylink"];
        if (paylink) {
          //console.log(paylink);
          setPaylink(paylink);
        } else {
          // notify("Service request sent successfully");

          Swal.fire({
            title: "Service Request",
            text: "Service request sent successfully. Check your email for feedback and quote from the service provider. Thank You!",
            icon: "success"
          });

          setLoading(false);
          document.getElementById("firstname").value = "";
          document.getElementById("lastname").value = "";
          document.getElementById("phone_number").value = "";
          document.getElementById("email").value = "";
          document.getElementById("address").value = "";
          document.getElementById("city").value = "";
          document.getElementById("region").value = "";
          document.getElementById("description").value = "";
          document.getElementById("startdate").value = "";
          document.getElementById("enddate").value = "";
        }
      })
      .catch((error) => {
        console.log(error);
      });

    // axios.get(`https://ghmarkets.net/test/ghmarketconnect/?ghw=requestservice&svg=det&prcode=${prodcode}`).then((response) => {
    //   setPost(response.data);
    // });
  };

  const handleChange = (event) => {
    //Check if email is a correct email
    const name = event.target.name;
    const value = event.target.value;

    if (name == "email") {
      setActualmail(value);
      // console.log(value);
      if (!validator.isEmail(value)) {
        setMessage("Please, enter a valid email!");
        setValidinput(false);
      } else {
        setMessage("");
      }
    }

    setInputs((inputs) => ({ ...inputs, [name]: value }));
    // setTextarea(event.target.value)
    // console.log(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    // Check if any required field is empty
    const requiredFields = [
      "firstname",
      "lastname",
      "phone_number",
      "email",
      "address",
      "city",
      "region",
      "description",
    ];
    const emptyFields = requiredFields.filter((field) => !inputs[field]);

    if (emptyFields.length > 0) {
      // If any required field is empty, throw an alert
      notifyError(
        `Please fill in the following fields: ${emptyFields.join(", ")}`
      );
      return;
    }

    if (validinput) {
      const captchaValue = recaptcha.current.getValue();
      if (captchaValue) {
        postData();
      } else {
        notifyError("Please verify the reCAPTCHA!");
      }
    } else {
      notifyError("There is an error in your form.");
    }
  };
  // useEffect(() => {

  // }, []);

  //check if the user is already logged in and populate details in the fields
  const getCustomerDetails = () => {
    fetch(
      `https://ghmarkets.net/test/ghmarketconnect/?ghw=login&svg=get_cust_details&email=${localStorage.getItem("username")}`
    )
      .then((response) => response.json())
      .then((json) => {
        // console.log(json["results"][0]);
        if (json["results"] && json["results"].length > 0) {
          setInputs((prevData) => ({
            ...prevData,
            firstname: json["results"][0].firstname,
            lastname: json["results"][0].lastname,
            phone_number: json["results"][0].phonenumber,
            email: json["results"][0].email,
            address: json["results"][0].address,
            region: json["results"][0].state,
            city: json["results"][0].city,
          }));
          setConfirmEmail(json["results"][0].email);
        }
      });
  };

  return (
    <>
      <Header />

      <section className="coupon-product-area pt-30 pb-60">
        <div className="container pb-30">
          <form onSubmit={handleSubmit} id="service-form">
            <div className="row mb-1">
              <NavLink to="/">
                <span>
                  <i className="fa fa-arrow-left"></i> Go back & continue
                  shopping
                </span>
              </NavLink>
            </div>
            <div className="row">
              <div className="col-sm-9 mb-20">
                <div className="card">
                  <div className="card-header">Customer Details</div>
                  <div className="card-body">
                    <h5 className="card-title">Add Details</h5>

                    <div className="form-row ">
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <label for="inputEmail4">First Name</label>
                            <input
                              value={inputs.firstname}
                              name="firstname"
                              type="text"
                              className="form-control"
                              id="firstname"
                              placeholder="First Name"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <label for="inputPassword4">Last Name</label>
                            <input
                              value={inputs.lastname}
                              name="lastname"
                              type="text"
                              className="form-control"
                              id="lastname"
                              placeholder="Last Name"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <label for="inputEmail4">Phone Number</label>
                            <input
                              value={inputs.phone_number}
                              name="phone_number"
                              type="text"
                              className="form-control"
                              id="phone_number"
                              placeholder="Phone Number"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <label for="email">
                              Email{" "}
                              <span style={{ color: "red" }}> {message} </span>{" "}
                            </label>
                            <input
                              value={inputs.email}
                              name="email"
                              type="email"
                              className="form-control"
                              id="email"
                              placeholder="Email"
                              onChange={handleChange}
                            />
                          </div>

                          <div className="col-md-6 mb-3">
                            <label htmlFor="confirmemail">
                              Confirm Email{" "}
                              <span style={{ color: "red" }}>
                                {" "}
                                {emailconfirmmsg}{" "}
                              </span>
                            </label>
                            <input
                              value={confirmEmail}
                              type="email" // Changed to email type
                              className="form-control"
                              id="confirmemail"
                              name="confirmemail"
                              placeholder="Confirm Email"
                              onChange={handleEmail}
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <label for="inputAddress">Delivery Address</label>
                            <input
                              value={inputs.address}
                              name="address"
                              type="text"
                              className="form-control"
                              id="address"
                              placeholder="1234 Main St"
                              onChange={handleChange}
                            />
                          </div>

                          <div className="col-md-6 mb-3">
                            <label for="inputCity">City</label>
                            <input
                              value={inputs.city}
                              name="city"
                              type="text"
                              className="form-control"
                              id="city"
                              onChange={handleChange}
                            />
                          </div>

                          <div className="col-md-6 mb-3">
                            <label for="inputregion">Region</label>
                            <select
                              className="form-control"
                              onChange={handleChange}
                              name="region"
                              id="region"
                              value={inputs.region} // Set the value to the selected region
                            >
                              <option>Choose a Region</option>
                              <option value="Ahafo Region">Ahafo Rgion</option>
                              <option value="Ashanti">Ashanti</option>
                              <option value="Bono East">Bono East</option>
                              <option value="Central">Central</option>
                              <option value="Eastern">Eastern</option>
                              <option value="Greater Accra">
                                Greater Accra
                              </option>
                              <option value="North East">North East</option>
                              <option value="Northern">Northern</option>
                              <option value="Oti">Oti</option>
                              <option value="Savannah">Savannah</option>
                              <option value="Upper East">Upper East</option>
                              <option value="Upper West">Upper West</option>
                              <option value="Volta">Volta</option>
                              <option value="Western">Western</option>
                              <option value="Western North">
                                Western North
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ----- Price Summary -- */}
              {quote ? (
                <div className="col-sm-3">
                  <div className="card">
                    <div className="card-header">Summary</div>
                    <div className="card-body">
                      <div className="coupon-product-content">
                        <div className="cart-summary-item">
                          <div className="cart-summary-item-label">
                            Subtotal
                          </div>
                          <div className="cart-summary-item-content">
                            GH₵ {quote}
                          </div>
                        </div>
                        <div className="cart-summary-item mt-2">
                          <div className="cart-summary-item-label">Tax</div>
                          <div className="cart-summary-item-content">GH₵ {taxAmt}</div>
                        </div>

                        <hr className="mt-15 mb-15" />

                        <div className="cart-summary-item">
                          <div className="cart-summary-item-label">Total</div>
                          <div className="cart-summary-item-content">
                            GH₵ {isNaN(totalAmount)?'-':totalAmount}
                          </div>
                        </div>

                        {/* <p className="mt-3">Delivery fees not included yet.</p> */}

                        {/* <button className="btn btn-block mt-5 disabled">
                    Pay now (GH₵{getCartProducts.productsAmt})
                  </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>

            {/* ---------------------- Request Details ------------------------------------------ */}
            <div className="row">
              <div className="col-sm-9 mb-20">
                <div className="card">
                  <div className="card-header">Request Details</div>
                  <div className="card-body">
                    <h5 className="card-title">Add Details</h5>

                    <div className="form-row ">
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <label for="priority">
                              Request Description (Please provide a detailed
                              description of your request)
                            </label>
                            <textarea
                              className="form-control"
                              rows="5"
                              style={{ resize: "none" }}
                              onChange={handleChange}
                              name="description"
                              id="description"
                            ></textarea>
                          </div>
                        </div>

                        {!quote ? (
                          <>
                            <div className="row">
                              <div className="col-md-6 mb-3">
                                <label>Start Date</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  name="startdate"
                                  onChange={handleChange}
                                  id="startdate"
                                />
                              </div>
                              <div className="col-md-6 mb-3">
                                <label>End Date</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  name="enddate"
                                  onChange={handleChange}
                                  id="enddate"
                                />
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-9 mb-20">
                <div className="card">
                

                <div className="card-footer d-flex justify-content-end gap-15">
                    <ReCAPTCHA
                      ref={recaptcha}
                      sitekey={process.env.REACT_APP_SITE_KEY}
                    />

                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      style={{ backgroundColor: "#F6921E" }}
                    >
                      Cancel
                    </button>
                    {quote ? (
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={loading}
                        style={{ backgroundColor: "#F6921E" }}
                      >
                        {loading ? (
                          <>
                            <i className="fas fa-circle-notch fa-spin"></i>{" "}
                            Please wait
                          </>
                        ) : (
                          <>
                            <i className="fa-solid fa-save"></i> Send Request ( GHS 
                            {isNaN(totalAmount)?'-': totalAmount } )
                          </>
                        )}
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={loading}
                        style={{ backgroundColor: "#F6921E" }}
                      >
                        {loading ? (
                          <>
                            <i className="fas fa-circle-notch fa-spin"></i>{" "}
                            Redirecting
                          </>
                        ) : (
                          <>
                            <i className="fa-solid fa-save"></i> Send Request
                          </>
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>

      {/* <Superdeals /> */}
      <ToastContainer />
    </>
  );
};

export default RequestService;
// works now
