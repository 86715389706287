import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { API_BASE_URL } from "../config/connect";
import Header from "../components/Header";
// import { useDispatch } from "react-redux";
// import { updateFirstName } from "../store/userActions"; // Import the action

const Account = () => {
  // const dispatch = useDispatch();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const notifyError = (msg) => toast.error(msg);
  const [result, setResult] = useState({});
  const [data, setData] = useState({
    fname: "",
    lname: "",
    phonenumber: "",
    email: "",
    address: "",
    confirmemail: "",
    region: "",
    city: "",
    user_id: "",
  });

  const handleInput = (event) => {
    const { name, value } = event.target;

    setData((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const notify = (mode, msg) => (mode !== "" && msg ? toast[mode](msg) : "");

  const handleCustomerDetail = async () => {
    // if (data.email !== data.confirmemail) {
    //   notifyError("Email mismatch!");
    //   setLoading(false);
    //   return;
    // }

    try {
      setLoading(true);
      const response = await axios.post(
        `https://ghmarkets.net/test/ghmarketconnect/?ghw=login&svg=account`,
        JSON.stringify(data)
      );
      // console.log(response.data);
      // Update local state with the new data
      setData((prevData) => ({
        ...prevData,
        ...response.data,
      }));
      //update the firstname to the new value
      localStorage.setItem("firstname", data.fname);

      // Dispatch action to update firstname in Redux store
      // dispatch(updateFirstName(data.fname));
      notify("success", "Account details updated successfully");
      setLoading(false);
    } catch (error) {
      console.error(error);
      notifyError("Error occurred while sending request");
      setLoading(false);
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    // Check if any required field is empty

    handleCustomerDetail();
  };

  useEffect(() => {
    const storedUsername = localStorage.getItem("username");
    if (storedUsername) {
      setUsername(storedUsername);
      getCustomerDetails();
    }
  }, []);

  const getCustomerDetails = () => {
    fetch(`https://ghmarkets.net/test/ghmarketconnect/?ghw=login&svg=get_cust_details&email=${localStorage.getItem("username")}`)
      .then((response) => response.json())
      .then((json) => {
        if (json["results"] && json["results"].length > 0) {
          setData((prevData) => ({
            ...prevData,
            fname: json["results"][0].firstname,
            lname: json["results"][0].lastname,
            phonenumber: json["results"][0].phonenumber,
            email: json["results"][0].email,
            address: json["results"][0].address,
            confirmemail: json["results"][0].email,
            region: json["results"][0].state,
            city: json["results"][0].city,
            user_id: json["results"][0].id,
          }));
        }
      });
  };

  return (
    <>
      <Header />
      <section className="coupon-product-area pt-30 pb-60">
        <div className="container pb-30">
          <div className="row mb-1">
            <NavLink onClick={() => navigate("/")}>
              <span>
                <i className="fa fa-arrow-left"></i> Go back & continue shopping
              </span>
            </NavLink>
          </div>

          <div className="row">
            <div className="col-sm-9 mb-20">
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  Customer Details
                </div>
                <div className="card-body">
                  <h5 className="card-title">Edit Details</h5>
                  <form onSubmit={handleSubmit}>
                    {/* Form Inputs */}
                    <div className="form-row ">
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <label htmlFor="fname">First Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="fname"
                              name="fname"
                              value={data.fname}
                              placeholder="First Name"
                              onChange={(event) => handleInput(event)}
                              required
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <label htmlFor="lname">Last Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="lname"
                              name="lname"
                              value={data.lname}
                              placeholder="Last Name"
                              onChange={(event) => handleInput(event)}
                              required
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <label htmlFor="phonenumber">Phone Number</label>
                            <input
                              type="text"
                              className="form-control"
                              id="phonenumber"
                              name="phonenumber"
                              value={data.phonenumber}
                              placeholder="Phone Number"
                              onChange={(event) => handleInput(event)}
                              required
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <label htmlFor="email">Email</label>
                            <input
                              type="text"
                              className="form-control"
                              id="email"
                              name="email"
                              value={data.email}
                              placeholder="Email"
                              onChange={(event) => handleInput(event)}
                              readOnly
                              required
                            />
                          </div>
                          {/* <div className="col-md-6 mb-3">
                            <label htmlFor="confirmemail">Confirm Email</label>
                            <input
                              type="text"
                              className="form-control"
                              id="confirmemail"
                              name="confirmemail"
                              value={data.confirmemail}
                              placeholder="Confirm Email"
                              onChange={(event) => handleInput(event)}
                              required
                            />
                          </div> */}
                          <div className="col-md-6 mb-3">
                            <label htmlFor="city">City</label>
                            <input
                              type="text"
                              className="form-control"
                              id="city"
                              name="city"
                              value={data.city}
                              placeholder="Accra"
                              onChange={(event) => handleInput(event)}
                              required
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <label htmlFor="address">Delivery Address</label>
                            <input
                              type="text"
                              className="form-control"
                              id="address"
                              name="address"
                              value={data.address}
                              placeholder="1234 Main St"
                              onChange={(event) => handleInput(event)}
                              required
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <label htmlFor="region">State</label>
                            <select
                              id="region"
                              name="region"
                              value={data.region}
                              className="form-control"
                              onChange={(event) => handleInput(event)}
                              required
                            >
                              <option defaultValue={true} disabled value={""}>
                                Choose a Region
                              </option>
                              <option value="Ahafo Rgion">Ahafo Rgion</option>
                              <option value="Ashanti">Ashanti</option>
                              <option value="Bono East">Bono East</option>
                              <option value="Central">Central</option>
                              <option value="Eastern">Eastern</option>
                              <option value="Greater Accra">
                                Greater Accra
                              </option>
                              <option value="North East">North East</option>
                              <option value="Northern">Northern</option>
                              <option value="Oti">Oti</option>
                              <option value="Savannah">Savannah</option>
                              <option value="pper East">Upper East</option>
                              <option value="Upper West">Upper West</option>
                              <option value="Volta">Volta</option>
                              <option value="Western">Western</option>
                              <option value="Western North">
                                Western North
                              </option>
                            </select>
                          </div>
                        </div>
                        {data.state}
                      </div>
                    </div>
                    <div className="card-footer d-flex justify-content-end gap-15">
                      <button
                        type="submit"
                        className="btn btn-outline-primary"
                        style={{ backgroundColor: "#F6921E" }}
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        style={{ backgroundColor: "#F6921E" }}
                      >
                        {loading ? (
                          <>
                            <i className="fas fa-circle-notch fa-spin"></i>{" "}
                            Saving...
                          </>
                        ) : (
                          <>Update</>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
};

export default Account;
