import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Pagination from "../components/Pagination";
import Header from "../components/Header";
import Trendingproducts from "../components/Trendingproducts";
import ViemoreProdSkeleton from "../components/ViemoreProdSkeleton";
 

function Viewmore({ products, ...selectedData }) {
  const { pageTitle } = selectedData;
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(9);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = products.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  // const paginate = pageNumber => setCurrentPage(pageNumber);

  const paginate = (data) => {
    let currentNumber = data.selected + 1;
    setCurrentPage(currentNumber);
  };
  const TruncateString = ({ text, maxLength }) => {
    const truncatedText = text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
  
    return <span>{truncatedText}</span>;
  };
  return (
    <div>
      <Header />

      <main>
        <div className="shop-area pt-90 pb-90">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-3 col-lg-4 col-md-8 col-sm-10 order-2 order-lg-0">
                <aside className="shop-sidebar">
                   <Trendingproducts>Trending Products</Trendingproducts>
                </aside>
              </div>
              <div className="col-xl-9 col-lg-8">
                <div className="shop-top-wrap mb-35">
                  <div className="shop-top-left">
                    <h5 className="title">{pageTitle}</h5>
                  </div>
                  <div className="shop-top-right">
                    <form action="#">
                      <label htmlFor="shortBy">sort By</label>
                      {/* <select id="shortBy" name="select" className="form-select" aria-label="Default select example">
                                            <option value="">Sorting</option>
                                            <option>Free Shipping</option>
                                            <option>Best Match</option>
                                            <option>Newest Item</option>
                                            <option>Size A - Z</option>
                                        </select> */}
                    </form>
                    <ul>
                      <li>View</li>
                      <li className="active">
                        <NavLink to="">
                          <i className="fa-solid fa-table-cells"></i>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="">
                          <i className="fa-solid fa-bars"></i>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="row justify-content-center">
                  {currentPosts.length > 0 ?
                    currentPosts.map((currentPost, index) => {
                      return (
                        <div
                          className="col-xl-4 col-lg-6 col-md-6 col-sm-8"
                          key={index}
                        >
                          <div className="shop-product-item mb-30">
                            <div className="shop-thumb">
                              <NavLink to={`/shopdetail/${currentPost.code}`}>
                                <img
                                  src={currentPost.previewpicture || currentPost.picture}
                                  alt="img"
                                  style={{
                                    height: "200px",
                                    objectFit: "cover",
                                  }}
                                />
                              </NavLink>
                              <span>New</span>
                            </div>
                            <div className="shop-content">
                              <ul className="tag">
                                <li>
                                  Sold by <NavLink to="">Market Store</NavLink>
                                </li>
                              </ul>
                              <h2 className="title">
                           

                                <NavLink to=""><p><TruncateString text={currentPost.name} maxLength={25} /></p></NavLink>
                              </h2>
                              <div className="rating d-flex">
      <div>  
      {/* Generate 5 star icons with yellow color for the rated stars */}
      {Array.from({ length: 5 }, (_, index) => (
        <i key={index} className="fa-solid fa-star" style={{color: index < Math.floor(currentPost.ratingaverage) ? '#ffc107' : '#e4e5e9'}}></i>
      ))}
      </div>
      <div>
      <h6 style={{fontWeight: 'normal',fontSize:'12px',marginLeft: '3px',marginTop: '10px'}}>({currentPost.ratingcount})</h6>
      </div>
    </div>

                                 {/* <RatingPerItem productCode={currentPost.code}/> */}

                                {/* <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i> */}
                               {/* <span>Already Sold : 75%</span> */}
                              {/* <div className="progress">
                                <div
                                  className="progress-bar w-75"
                                  role="progressbar"
                                  aria-valuenow="75"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div> */}
                              <div className="content-bottom">
                                <h4 className="price">{currentPost.price.toLocaleString()}</h4>
                                <p>
                                  0 orders <span>-35%</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }): <ViemoreProdSkeleton/>
                  }
                </div>
                <div className="shop-bottom-wrap">
                {products.length > 0 ? (
                  <div className="shop-bottom-top">
                    <h5 className="title">Shop</h5>
                    <p>
                      Showing {indexOfFirstPost + 1} to{" "}
                      {indexOfLastPost > products.length
                        ? products.length
                        : indexOfLastPost}{" "}
                      of {products.length} Item(s)
                    </p>
                    <Pagination
                      postsPerPage={postsPerPage}
                      totalPosts={products.length}
                      paginate={paginate}
                    />
                  </div>
                ) : (
                    ""
                  )}
                  <div className="shop-bottom-box">
                    <div className="shop-bottom-left">
                      <form action="#">
                        {/* <select id="short-By" name="select" className="form-select" aria-label="Default select example">
                                                <option value="">Show 09</option>
                                                <option>Show 12</option>
                                                <option>Show 08</option>
                                                <option>Show 06</option>
                                                <option>Show 03</option>
                                            </select> */}
                      </form>
                    </div>
                    <div className="shop-bottom-right">
                      <form action="#">
                        {/* <select id="short-by" name="select" className="form-select" aria-label="Default select example">
                                                <option value="">Default sorting</option>
                                                <option>Free Shipping</option>
                                                <option>Best Match</option>
                                                <option>Newest Item</option>
                                                <option>Size A - Z</option>
                                            </select> */}
                      </form>
                      <ul>
                        <li className="active">
                          <NavLink to="#">
                            <i className="fa-solid fa-table-cells"></i>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="#">
                            <i className="fa-solid fa-bars"></i>
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Viewmore;
