import React, { useState, useEffect } from "react";
// import './ProgressBar.css'; // Import your CSS file

const ProgressBar = ({ eventEndTime }) => {
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date();
      const endTime = new Date(eventEndTime);
      const totalTime = endTime - currentTime;
      const timeLeftPercentage = (totalTime / (endTime - currentTime)) * 100;
      setProgress(timeLeftPercentage);
    }, 1000);

    return () => clearInterval(interval);
  }, [eventEndTime]);

  let color = "green"; // Default color green

  if (progress <= 10) {
    color = "#FF0000"; // Change color to red when 10% or less time left
  } else if (progress <= 30) {
    color = "#FFFF00"; // Change color to yellow when 30% or less time left
  }

  return (
    <div className="progress">
      <div
        className="progress-bar"
        role="progressbar"
        style={{ width: `${progress}%`, backgroundColor: "green" }}
        aria-valuenow={progress}
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
    // <div className="progress-bar-container" style={{width: '100%', backgroundColor:"#ccc",borderRadius:'5px'}}>
    //   <div
    //     className="progress-bar"
    //     style={{ width: `${progress}%`, backgroundColor: color,height:'10px',borderRadius:'5px'}}
    //   ></div>
    // </div>
  );
};

export default ProgressBar;
