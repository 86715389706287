import React, { useState, useEffect } from "react";
import {API_BASE_URL} from "../config/connect";
import { NavLink } from "react-router-dom";
import  Tooltip  from "@mui/material/Tooltip";
import RatingPerItem from "./RatingPerItem";
import ProductSkeletonLoader from "./ProductSkeletonLoader";


function Hotproduct() {
  const [hots, setHotproduct] = useState("");
  

  const getAllHotProduct = () => {
    fetch(`https://ghmarkets.net/test/ghmarketconnect/?ghw=productservices&svg=hotproduct&cls=min`)
      .then((response) => response.json())
      .then((json) => {
        // console.log(json["results"]);
        setHotproduct(json["results"].slice(0, 6));
      });
  };

  useEffect(() => {
    getAllHotProduct();
  }, []);

  const TruncateString = ({ text, maxLength }) => {
    const truncatedText = text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
  
    return <h6>{truncatedText}</h6>;
  };
  return (
    <div>
      <section className="super-deals-product-area pt-60 pb-20">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="product-wrap mb-30">
                <div className="row align-items-center mb-20">
                  <div className="col-xl-3 col-lg-4 col-md-3">
                    <div className="section-title">
                      <h2 className="title">
                    <span>Hot</span> Products
                      </h2>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-7">
                    <div className="super-deals-countdown">
                      <p>Top products. Incredible prices</p>
                      <div
                        className="coming-time"
                        data-countdown="2023/4/30"
                      ></div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-2 col-md-2">
                    <div className="view-more text-end">
                    <NavLink to ="/viewmore/hotproducts">View more</NavLink>
                    </div>
                  </div>
                </div>
                <div className="row custom justify-content-center">
                  {hots.length > 0 ?
                    hots.map((hot) => {
                      return (
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6" key={hot.id}>
                          <div className="product-item mb-30">
                            <div className="product-thumb">
                            <NavLink to={`shopdetail/${hot.code}`}>
                              <img src={hot.previewpicture || hot.picture} alt="" style={{height:'200px',objectFit:'cover'}}  />
                              </NavLink>
                            </div>
                            <div className="product-content">
                            <h4 className='title'>
																<Tooltip title={<span style={{color: 'white',fontSize: '13px',display: 'inline-block'}}>{hot.name}</span>}  arrow>
																<NavLink to={`shopdetail/${hot.code}`}>
															 <p><TruncateString text={hot.name} maxLength={25} /></p> 

													 
																	{` ₵${hot.price.toLocaleString()}`}
 																</NavLink>
									 
								</Tooltip>
															</h4>
                              <div className="rating d-flex">
      <div>  
      {/* Generate 5 star icons with yellow color for the rated stars */}
      {Array.from({ length: 5 }, (_, index) => (
        <i key={index} className="fa-solid fa-star" style={{color: index < Math.floor(hot.ratingaverage) ? '#ffc107' : '#e4e5e9'}}></i>
      ))}
      </div>
      <div>
      <h6 style={{fontWeight: 'normal',fontSize:'12px',marginLeft: '3px',marginTop: '4px'}}>({hot.ratingcount})</h6>
      </div>
    </div>
 															{/* <p><RatingPerItem productCode={hot.code} margin="5px"/></p> */}
                              {/* <h4 className="title"> */}
{/* 
                              <NavLink to={`shopdetail/${hot.code}`}>
																				{hot.name}{` ₵${hot.price}`}<span>-25%</span>
																			</NavLink> */}
                                {/* <a href="shop-details.html">
                                {hot.name} {`₵${hot.price}`}<span>-25%</span>
                                </a> */}
                              {/* </h4> */}
                              {/* <p>05 orders</p> */}
                            </div>
                          </div>
                        </div>
                      );
                    }): <ProductSkeletonLoader height={"200px"} length={6} xl={2}/>}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-9">
              <div className="product-wrap top-product mb-20">
                <div className="row mb-20">
                  <div className="col-sm-6">
                    <div className="product-title">
                      <h4 className="title">Top Selection</h4>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="view-more text-end">
                      <a href="shop.html">View more</a>
                    </div>
                  </div>
                </div>
                <div className="row custom justify-content-center">
                  <div className="col-md-4 col-sm-6">
                    <div className="product-item mb-30">
                      <div className="product-thumb">
                        <a href="shop-details.html">
                          <img src={Super_Product07} alt="" />
                        </a>
                      </div>
                      <div className="product-content">
                        <h4 className="title">
                          <a href="shop-details.html">
                            launch $29.08<span>-30%</span>
                          </a>
                        </h4>
                        <p>40 orders</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="product-item mb-30">
                      <div className="product-thumb">
                        <a href="shop-details.html">
                          <img src={Super_Product08} alt="" />
                        </a>
                      </div>
                      <div className="product-content">
                        <h4 className="title">
                          <a href="shop-details.html">
                            Watch $29.08<span>-40%</span>
                          </a>
                        </h4>
                        <p>20 orders</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="product-item mb-30">
                      <div className="product-thumb">
                        <a href="shop-details.html">
                          <img src={Super_Product09} alt="" />
                        </a>
                      </div>
                      <div className="product-content">
                        <h4 className="title">
                          <a href="shop-details.html">
                            phone $29.08<span>-10%</span>
                          </a>
                        </h4>
                        <p>30 orders</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-9">
              <div className="product-wrap top-product mb-20">
                <div className="row mb-20">
                  <div className="col-sm-6">
                    <div className="product-title">
                      <h4 className="title">New arrivals</h4>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="view-more text-end">
                      <a href="shop.html">View more</a>
                    </div>
                  </div>
                </div>
                <div className="row custom justify-content-center">
                  <div className="col-md-4 col-sm-6">
                    <div className="product-item mb-30">
                      <div className="product-thumb">
                        <a href="shop-details.html">
                          <img src={Super_Product10} alt="" />
                        </a>
                      </div>
                      <div className="product-content">
                        <h4 className="title">
                          <a href="shop-details.html">
                            launch $29.08<span>-30%</span>
                          </a>
                        </h4>
                        <p>40 orders</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="product-item mb-30">
                      <div className="product-thumb">
                        <a href="shop-details.html">
                          <img src={Super_Product11} alt="" />
                        </a>
                      </div>
                      <div className="product-content">
                        <h4 className="title">
                          <a href="shop-details.html">
                            Watch $29.08<span>-40%</span>
                          </a>
                        </h4>
                        <p>20 orders</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="product-item mb-30">
                      <div className="product-thumb">
                        <a href="shop-details.html">
                          <img src={Super_Product12} alt="" />
                        </a>
                      </div>
                      <div className="product-content">
                        <h4 className="title">
                          <a href="shop-details.html">
                            phone $29.08<span>-10%</span>
                          </a>
                        </h4>
                        <p>30 orders</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </div>
  );
}

export default Hotproduct;