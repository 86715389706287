import React from 'react';
import { ToastContainer } from 'react-toastify';
import Header from '../components/Header';

const OrderHow = () => {
	return (
		<>
			<Header />
			<section className='privacy-policy-section'>
				<div className='container'>
					<div className='row justify-content-center m-5'>
						<div className='col-lg-8'>
							<div className='card shadow p-4'>
								<div className='card-header text-white text-center'>
									<h5 className='mb-0'> How To Order </h5>{' '}
								</div>{' '}
								<div className='card-body'>
									<p className='lead'>
									Ordering on Africa Markets is quick and easy. Follow these simple steps to place your order: .{' '}
									</p>{' '}
									<h2 className='text-center '>
									Step 1: Sign in or Create an Account {' '}
										</h2>{' '}
									<p className='lead'>
									 
                                    If you're a returning customer, sign in to your account using your email address and password. If you're new to Africa Markets, click on the "Sign Up" or "Create Account" button to register. </p>{' '}


									<h2 className='text-center '>
									Step 2: Browse Products {' '}
										</h2>{' '}
									<p className='lead'>
									 
									Browse through our wide selection of products using the search bar or by navigating through categories. Click on a product to view more details, such as price, description, and available variants. </p>{' '}


									<h2 className='text-center '>
									Step 3: Add to Cart {' '}
										</h2>{' '}
									<p className='lead'>
                                    Once you've found the product you want to purchase, select the quantity and any desired options (e.g., size, color) and click on the "Add to Cart" button. You can continue shopping or proceed to checkout. </p>{' '}


									<h2 className='text-center '>
									Step 4: Review Your Cart {' '}
										</h2>{' '}
									<p className='lead'>
									Review the items in your cart to ensure everything is correct. You can update quantities, remove items, or apply any applicable promo codes or discounts. </p>{' '}


<h2 className='text-center '>
Step 5: Checkout {' '}
										</h2>{' '}
									<p className='lead'>
									 
                                    When you're ready to place your order, click on the "Checkout" button. You'll be prompted to enter your shipping address, select a shipping method, and choose your preferred payment method. </p>{' '}

									<h2 className='text-center '>
									Step 6: Review and Place Order {' '}
										</h2>{' '}
									<p className='lead'>
									Review your order summary, including the total amount due, shipping details, and payment information. Make sure all information is accurate, then click on the "Place Order" button to complete your purchase.  </p>{' '}

									<h2 className='text-center '>
                                    Step 7: Confirmation  {' '}
										</h2>{' '}
									<p className='lead'>
									 
                                    After placing your order, you'll receive an order confirmation email with details about your purchase, including the order number and estimated delivery date. You can also track the status of your order through your account dashboard. </p>{' '}

                                    
									<h2 className='text-center '>
									Step 8: Sit Back and Relax {' '}
										</h2>{' '}
									<p className='lead'>
									 
                                    Once your order is processed, our team will handle the rest. Sit back, relax, and await delivery of your products right to your doorstep. 	</p>{' '}

                                    <p className='lead'>
									 
                                    If you have any questions or need assistance at any point during the ordering process, feel free to contact our customer support team for help. 	</p>{' '}


									
									{/* Add more content for the Privacy Policy */}{' '}
								</div>{' '}
							</div>{' '}
						</div>{' '}
					</div>{' '}
				</div>{' '}
			</section>{' '}
			<ToastContainer />
		</>
	);
};

export default OrderHow;
