import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../config/connect";

const ControlMethod = ({ onControlMethodSelected }) => {
  const [selectedControlMethod, setSelectedControlMethod] = useState(null);
  const [variations, setVariations] = useState([]);
  const { prodcode } = useParams();

  const getAllProductVariations = async () => {
    try {
      const response = await fetch(
        `https://ghmarkets.net/test/ghmarketconnect/?ghw=productservices&svg=fashionvariations&prcode=${prodcode}`
      );
      const json = await response.json();
      const firstResult = json["results"][0];
      if (firstResult && firstResult.controlmethod) {
        const controlMethodObject = firstResult.controlmethod;
        if (controlMethodObject.trim() !== "") {
          setVariations(controlMethodObject.split(","));
        }
      }
    } catch (error) {
      // Handle error here
    }
  };

  useEffect(() => {
    getAllProductVariations();
  }, [prodcode]);

  const handleControlMethodClick = (controlmethod) => {
    setSelectedControlMethod(controlmethod);
    onControlMethodSelected(controlmethod);
  };

  return variations.length > 0 ? (
    <div className="sizes-container" style={{ display: "flex", flexDirection: "row" }}>
      <span style={{ fontSize: '', fontWeight: 'bold', color: 'black', marginTop: '12px' }}>Control Method:</span>
      <div className="sizes">
        {variations.map((variation, i) => (
          <div
            key={i}
            className={`size ${selectedControlMethod === variation ? "selected" : ""}`}
            onClick={() => handleControlMethodClick(variation)}
          >
            {variation}
          </div>
        ))}
      </div>
    </div>
  ) : (
    ""
  );
};

export default ControlMethod;
