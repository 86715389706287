import React from "react";
import Header from "../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { removeWishList } from "../store/wishListSlice";

const Wishlist = () => {
  const dispatch = useDispatch();

  const wishList = useSelector((state) => state.wishlist);

  const wishListItems = JSON.parse(localStorage.getItem("wishlist"));

  const getWishListItems =
    wishList.products?.length > 0 ? wishList : wishListItems;
  console.log("wishListItems: ", wishListItems);
  console.log(`getWishListItems: ${getWishListItems}`);

  const removeFromWishList = (id) => {
    dispatch(removeWishList(id));
    console.log(`WishListItem: ${id}`);
  };

  return (
    <>
      <Header />

      <section className="coupon-product-area pt-30 pb-60">
        <div className="container">
          <div className="row">
            {/* Cart Section */}
            <div className="col-lg-9">
              <div className="coupon-product-item-wrap mb-30">
                <div
                  className="card-header"
                  style={{ background: "transparent", border: "none" }}
                >
                  <h3 className="title text-white">
                    Wishlist
                    {getWishListItems?.products?.length > 0 &&
                      `(${getWishListItems?.products?.length})`}
                  </h3>
                </div>

                {console.log(getWishListItems)}

                {getWishListItems?.products?.length > 0 &&
                  getWishListItems?.products.map((product) => (
                    <div className="row align-items-center cart-item mt-2">
                      <div className="col-lg-9">
                        <div className="coupon-product-item">
                          <div className="coupon-product-thumb">
                            <img src={product.picture} alt="img" />
                          </div>
                          <div className="coupon-product-content mt-25">
                            <h3 className="title">{product.name}</h3>
                            <p>
                              <span>Seller:</span> Isakaba Ventures |{" "}
                              <span>Size:</span> L
                            </p>
                            <h4 className="price">GHC{product.price}</h4>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 cr">
                        <div
                          className="btn-group cart-rm-item"
                          onClick={() => removeFromWishList(product.prodcode)}
                        >
                          <i className="fas fa-trash"></i>
                        </div>
                        <div className="coupon-code text-end ">
                          {/* <div className="adjust-qty">
                            <button
                              className="btn btn-sm px-2 cart-btn"
                                onClick={() => decrementItemQty(product.prodcode)}
                            >
                              <i className="fas fa-minus"></i>
                            </button>
                            <div className="cart-item-qty pl-5 pr-5 fs-6">
                              {product.quantity}
                            </div>
                            <button
                              className="btn btn-sm px-2 cart-btn"
                                onClick={() => incrementItemQty(product.prodcode)}
                            >
                              <i className="fas fa-plus"></i>
                            </button>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  ))}

                {(getWishListItems?.products?.length < 1 ||
                  getWishListItems?.products == null) && (
                  <div className="row align-items-center cart-item">
                    <div className="col-lg-9">
                      <div className="coupon-product-item">
                        <div className="coupon-product-content mt-25">
                          <h3 className="title">
                            You have no items in your wishlist
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* <div className="row align-items-center cart-item">
                  <div className="col-lg-9">
                    <div className="coupon-product-item">
                      <div className="coupon-product-thumb">
                        <img src={coupon_product01} alt="img" />
                      </div>
                      <div className="coupon-product-content mt-25">
                        <h3 className="title">
                          Cap Caps New Cotton Baseball Cap With Embroidered
                        </h3>
                        <p>
                          <span>Seller:</span> Isakaba Ventures |{" "}
                          <span>Size:</span> L
                        </p>
                        <h4 className="price">$39.35</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 cr">
                    <div className="btn-group cart-rm-item">
                      <i className="fas fa-trash"></i>
                    </div>
                    <div className="coupon-code text-end ">
                      <div className="adjust-qty">
                        <button className="btn btn-sm px-2 cart-btn">
                          <i className="fas fa-minus"></i>
                        </button>
                        <span className="cart-item-qty">1</span>
                        <button className="btn btn-sm px-2 cart-btn">
                          <i className="fas fa-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Wishlist;
