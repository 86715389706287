import React from "react";
import { NavLink } from "react-router-dom";
 
function Topbar() {
 
  return (
    <>
      <div>
        <div className="header-top-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="header-top-left">
                  <a href="/">Welcome! Ghana first stop online market</a>
                </div>
              </div>
              <div className="col-md-6">
                <div className="header-top-right">
                {/* <ul class="links">
    <li><a href="#">About</a></li>
    <li class="dropdown"><a href="#" class="trigger-drop ">Work<i class="arrow white-text"></i></a>
      <ul class="drop">
        <li><a href="#">Art</a></li>
        <li><a href="#">Photography</a></li>
        <li><a href="#">Audio</a></li>
        <li><a href="#">Films</a></li>
      </ul>
    </li>
    <li class="dropdown"><a href="#" class="trigger-drop">Contact<i class="arrow"></i></a>
      <ul class="drop">
        <li><a href="#">Email</a></li>
        <li><a href="#">Phone</a></li>
      </ul>
    </li>
  </ul> */}
                  <ul>
  
                      <li>
                        <a target="blank" href="https://ghmarkets.net/test/vendorpanel/index.php?action=login">Vendor Login</a>
                      </li>
                 
                    <li>
                      <NavLink to="./contact">Contact Us</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ToastContainer /> */}
    </>
  );
}

export default Topbar;
