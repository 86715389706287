import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../config/connect";
import "../assets/css/main.css";
import "../assets/css/util.css";
import Header from "../components/Header";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const ResetPassword = () => {
  const notify = (msg) => toast.success(msg);
  const notifyError = (msg) => toast.error(msg);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [error, setError] = useState("");
  const [oldPassErr, setOldPassErr] = useState("");
  const [data, setData] = useState({
    password: "",
    lastname: "",
    firstname: "",
    user_id: "",
    email: "",
    old_pass: "",
    confirmpass: "",
  });

  const handleLogin = async (e) => {
    e.preventDefault();
    if (validatePasswords()) {
      await handleCustomerDetail();
      setError("");
    } else {
      setError("Passwords do not match");
    }
  };

  const validatePasswords = () => {
    return data.password === data.confirmpass;
  };

  const handleCustomerDetail = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `https://ghmarkets.net/test/ghmarketconnect/?ghw=login&svg=change_password`,
        JSON.stringify(data)
      );

      console.log(response);
      if (response.data == " password not found") {
        setOldPassErr("Current password is incorrect!");
        setLoading(false);
      } else {
        notify("Password updated successfully");
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      notifyError("Error occurred while sending request");
      setLoading(false);
    }
  };

  const getCustomerDetails = () => {
    fetch(
      `https://ghmarkets.net/test/ghmarketconnect/?ghw=login&svg=get_cust_details&email=${localStorage.getItem("username")} `
    )
      .then((response) => response.json())
      .then((json) => {
        if (json["results"] && json["results"].length > 0) {
          // console.log(json["results"][0]);

          setData((prevData) => ({
            ...prevData,
            firstname: json["results"][0].firstname,
            lastname: json["results"][0].lastname,
            user_id: json["results"][0].id,
            email: json["results"][0].email,
            // old_pass: json["results"][0].password,
          }));
        }
      });
  };

  useEffect(() => {
    const storedUsername = localStorage.getItem("username");
    if (storedUsername) {
      setUsername(storedUsername);
      getCustomerDetails();
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "old_pass" && oldPassErr !== "") {
      setOldPassErr("");
    }

    if(name === "password" || name === "confirmpass"){
      setError("")
    }

    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <Header />
      <div className="limiter">
        <div className="container-login100">
          <div className="wrap-login100">
            <form
              className="login100-form validate-form p-l-55 p-r-55 p-t-178"
              onSubmit={handleLogin}
            >
              <span className="login100-form-title">Reset Password</span>

              <div
                className="wrap-input100 validate-input m-b-16"
                data-validate="Please enter email"
              >
                <input
                  className="input100"
                  type="text"
                  name="old_pass"
                  value={data.old_pass}
                  onChange={handleChange}
                  placeholder="Enter current password"
                />
                <span className="focus-input100"></span>
              </div>
              {oldPassErr && (
                <p style={{ color: "red", textAlign: "center",marginTop:"-15px" }}>
                  {oldPassErr}
                </p>
              )}
              <div
                className="wrap-input100 validate-input m-b-16"
                data-validate="Please enter email"
              >
                <input
                  className="input100"
                  type="text"
                  name="password"
                  value={data.password}
                  onChange={handleChange}
                  placeholder="Enter new password"
                />
                <span className="focus-input100"></span>
              </div>

              <div
                className="wrap-input100 validate-input"
                data-validate="Please enter password"
              >
                <input
                  className="input100"
                  type="text"
                  name="confirmpass"
                  value={data.confirmpass}
                  onChange={handleChange}
                  placeholder="Repeat password"
                />
                <span className="focus-input100"></span>
              </div>
              {error && (
                <p style={{ color: "red", textAlign: "center" }}>{error}</p>
              )}
              <div className="container-login100-form-btn m-t-20">
                <button className="login100-form-btn">
                  {loading ? (
                    <i className="fas fa-circle-notch fa-spin"></i>
                  ) : (
                    "Reset"
                  )}
                </button>
              </div>
              <div className="flex-col-c p-t-120 p-b-30"></div>

            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ResetPassword;
