import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../config/connect";

const TrackingFeatures = ({ onTrackingFeaturesSelected }) => {
  const [selectedTrackingFeatures, setSelectedTrackingFeatures] = useState(null);
  const [variations, setVariations] = useState([]);
  const { prodcode } = useParams();

  const getAllProductVariations = async () => {
    try {
      const response = await fetch(
        `https://ghmarkets.net/test/ghmarketconnect/?ghw=productservices&svg=fashionvariations&prcode=${prodcode}`
      );
      const json = await response.json();
      const firstResult = json["results"][0];
      if (firstResult && firstResult.trackingfeatures) {
        const trackingFeaturesObject = firstResult.trackingfeatures;
        if (trackingFeaturesObject.trim() !== "") {
          setVariations(trackingFeaturesObject.split(","));
        }
      }
    } catch (error) {
      // Handle error here
    }
  };

  useEffect(() => {
    getAllProductVariations();
  }, [prodcode]);

  const handleTrackingFeaturesClick = (trackingfeatures) => {
    setSelectedTrackingFeatures(trackingfeatures);
    onTrackingFeaturesSelected(trackingfeatures);
  };

  return variations.length > 0 ? (
    <div className="sizes-container" style={{ display: "flex", flexDirection: "row" }}>
      <span style={{ fontSize: '', fontWeight: 'bold', color: 'black', marginTop: '12px' }}>Tracking Features:</span>
      <div className="sizes">
        {variations.map((variation, i) => (
          <div
            key={i}
            className={`size ${selectedTrackingFeatures === variation ? "selected" : ""}`}
            onClick={() => handleTrackingFeaturesClick(variation)}
          >
            {variation}
          </div>
        ))}
      </div>
    </div>
  ) : (
    ""
  );
};

export default TrackingFeatures;
