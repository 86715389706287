import React, { createContext, useContext, useEffect, useState } from "react";
import { API_BASE_URL } from "../config/connect";
import Loader from "../views/Loader";

const AllProductContext = createContext();

export function useProductData() {
  return useContext(AllProductContext);
}

export const ProductsContextApi = ({ children }) => {
  const [allProductData, setAllproductData] = useState(null);
  const [allproImage, setAllProimage] = useState([]);
  const [subcategories, setsubCategories] = useState("");
  const [brands, setBrands] = useState("");
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [loading3, setLoading3] = useState(true);
  const [loading4, setLoading4] = useState(true);


  const getAllProducts = () => {
    fetch(`https://ghmarkets.net/test/ghmarketconnect/?ghw=productservices&svg=allproduct`)
      .then((response) => response.json())
      .then((json) => {
        //console.log(json);
        setAllproductData(json["results"]);
        setLoading1(false);
      });
  };

  const getAllSubCategories = () => {
    fetch(`https://ghmarkets.net/test/ghmarketconnect/?ghw=subcategories&svg=list`)
      .then((response) => response.json())
      .then((json) => {
        // console.log(json['results'])
        setsubCategories(json["results"]);
        setLoading2(false)
      });
  };


  const getAllBrands = () => {
    fetch(`https://ghmarkets.net/test/ghmarketconnect/?ghw=productbrand&svg=list`)
      .then((response) => response.json())
      .then((json) => {
        //console.log(json['results'])
        setBrands(json["results"]);
        setLoading3(false)
      });
  };

  
   
  const getAllImages = () => {
    try {
      setTimeout(() => {
      fetch(`https://ghmarkets.net/test/ghmarketconnect/?ghw=productservices&svg=subimages`)
        .then((response) => response.json())
        .then((json) => {
          //console.log(json);
          setAllProimage(json["results"]);
          setLoading4(false);
        });
      }, 20000);
    } catch (error) {
      console.error("Internet Connection Down", error);
      throw error;
    }
  };

  
  useEffect(() => {

    getAllImages();
    getAllProducts();
    getAllBrands();
    getAllSubCategories();
  }, []);

  if (loading1 || loading2 || loading3) {
    // Return loading indicator if either of the contexts is still loading
    return <Loader />;
  }

  return (
    <AllProductContext.Provider value={{allProductData, allproImage, subcategories, brands}}>
        {children}
    </AllProductContext.Provider>
  );
};

// export default ProductsContextApi;