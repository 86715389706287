import React from "react";
import Header from "../components/Header";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { removeFromCompare } from "../store/compareSlice";
import { add } from "../store/cartSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CompareProducts = () => {
  const dispatch = useDispatch();
  const compareList = useSelector((state) => state.compareproduct);

  const compareListItems = JSON.parse(localStorage.getItem("compare"));

  const getCompareListItems =
    compareList?.length > 0 ? compareList : compareListItems;
  console.log("compareListItems: ", compareListItems);
  console.log(getCompareListItems);

  const removeCompare = (id) => {
    dispatch(removeFromCompare(id));
    console.log("physical present...");
  };

  const addToCart = (
    prodcode,
    name,
    price,
    description,
    picture,
    model,
    sellercode,
    servicetype
  ) => {
    const product = {
      prodcode,
      name,
      price,
      description,
      picture,
      model,
      sellercode,
      servicetype,
    };
    // dispatch an add action
    notify("Product added successfully!");
    dispatch(add(product));
  };

  const notify = (msg) => toast.success(msg);

  return (
    <>
      <Header />

      <main>
        <div className="shop-area pt-90 pb-90">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="flash-sale-product mb-55">
                  {/* <h2 className="title">Flash Sale Today!</h2> */}

                  <div className="row g-3 d-flex align-items-center justify-content-center">
                    {getCompareListItems?.length > 0 &&
                      getCompareListItems?.map((compproduct) => (
                        <div className="col-lg-3 col-md-6 col-sm-6">
                          <div className="flash-sale-item mb-30">
                            <div className="fs-thumb">
                              <a href="shop-details.html">
                                <img src={compproduct.previewpicture || compproduct.picture} alt="img" />
                              </a>
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  removeCompare(compproduct.prodcode)
                                }
                              >
                                Remove
                              </span>
                            </div>
                            <div className="fs-content">
                              <a href="#" className="tag">
                                Smart Phone
                              </a>
                              <h4 className="title">
                                <a href="shop-details.html">
                                  {compproduct.name}
                                </a>
                              </h4>
                              <div className="rating">
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                              </div>
                              <span>Already Sold : 75%</span>
                              <div className="progress">
                                <div
                                  className="progress-bar w-75"
                                  role="progressbar"
                                  aria-valuenow="75"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                              <div className="content-bottom">
                                <h4 className="price">$39.08</h4>
                                <p>
                                  0 orders <span>-35%</span>
                                </p>
                              </div>
                              <div className="brand d-flex mt-2 mb-2 gap-15">
                                <span className="brand-title">Brand:</span>
                                <span className="brand-name">Samsung</span>
                              </div>
                              <div className="brand d-flex mt-2 mb-2 gap-15">
                                <span className="brand-title">Model:</span>
                                <span className="brand-name">i10</span>
                              </div>
                              <div className="description border-top border-bottom pt-2 pb-2">
                                {/* <div className="desc-title">Description</div> */}
                                {/* <div className="desc-desc"></div> */}
                                {compproduct.description}
                              </div>
                              <div className="buy-btn shop-details-quantity mt-3 d-flex justify-content-center">
                                <NavLink
                                  className="wishlist-btn"
                                  onClick={() =>
                                    addToCart(
                                      compproduct.prodcode,
                                      compproduct.name,
                                      compproduct.price,
                                      compproduct.description,
                                      compproduct.picture,
                                      compproduct.model,
                                      compproduct.sellercode,
                                      compproduct.servicetype
                                    )
                                  }
                                >
                                  <i className="fa-solid fa-cart-arrow-down"></i>
                                  Add to Cart
                                </NavLink>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                    {(getCompareListItems?.length < 1 ||
                      getCompareListItems == null) && (
                      <div className="row align-items-center cart-item">
                        <div className="col-lg-12">
                          <div className="coupon-product-item d-flex justify-content-center">
                            <div className="coupon-product-content mt-15">
                              <h3 className="title">
                                Nothing to compare here. Select products to
                                compare or not!
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <ToastContainer />
    </>
  );
};

export default CompareProducts;
