import React, { useEffect, useState, Suspense, useContext } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../config/connect";
import { add } from "../store/cartSlice";
import { addWishList } from "../store/wishListSlice";
import { addToCompare } from "../store/compareSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Rating from "../components/Rating";
import shop_nav_img01 from "../assets/img/product/shop_nav_img01.jpg";
import shop_nav_img02 from "../assets/img/product/shop_nav_img02.jpg";
import shop_nav_img03 from "../assets/img/product/shop_nav_img03.jpg";
// import RecommendedProds from "../components/RecommendedProds";
import Header from "../components/Header";
import Productsimages from "../components/Productsimages";
// import Sizes from "../components/variations/Sizes";
// import ProductColor from "../components/variations/ProductColor";
import ProdImageSkeleton from "../components/ProdImageSkeleton";
import RecommendedProdsSkeleton from "../components/RecommendedProdsSkeleton";
import ProductVariations from "../components/ProductVariations";
// import Material from "../components/Variations/Material";
import { useProductData } from "../components/ProductsContextApi";


function Shopdetail() {
  let { prodcode } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [quantity, setQuantity] = useState(1);
  const [selectedVariations, setSelectedVariations] = useState({});

  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [sku, setSKU] = useState("");
  const notify = (msg) => toast.success(msg);
  const [sellerregion, setRegion] = useState("");
  const [selectedMaterial, setSelectedMaterial] = useState(null);

  const allprods = useProductData().allProductData;
  const specItem = allprods?.find((product) => product.code === prodcode);
  //console.log(specItem);
  const recommendedProducts = allprods?.filter((product) => product.categorycode == specItem.categorycode && product.subcategorycode == specItem.subcategorycode && product.brand != specItem.brand)
  const Swal = require("sweetalert2");

  
  const addToCart = (
    prodcode,
    name,
    price,
    description,
    picture,
    model,
    sellercode,
    servicetype,
    quantity,
    categorycode,
    subcategorycode,
    sellername,
    selectedVariations
  ) => {
    const product = {
      prodcode,
      name,
      price,
      description,
      picture,
      model,
      sellercode,
      servicetype,
      quantity,
      categorycode,
      subcategorycode,
      sellername,
      selectedVariations,
    };
    // dispatch an add action
    //notify("Product added successfully!");
    Swal.fire({
      title: "Add To Cart",
      text: "Product added successfully to cart!",
      icon: "success"
    });
    dispatch(add(product));
  };

  const getRegion = async () => {
    
    try{
      
      const response = await fetch(`https://ghmarkets.net/test/ghmarketconnect/?ghw=productservices&svg=sellerRegion&prcode=${specItem.sellercode}`);
      const json = await response.json();
      // const region = json;
      setRegion(json)
      // console.log(json);

    }catch (error){
      console.error("Error fetching region data",error);
    }
    
  };

  useEffect(() => {
    getRegion();
  });
  

  const addToWishList = async (
    prodcode,
    name,
    price,
    description,
    picture,
    model,
    sellercode,
    servicetype
  ) => {
    const product = {
      prodcode,
      name,
      price,
      description,
      picture,
      model,
      sellercode,
      servicetype,
    };
    // dispatch an add action
    notify("Product saved to your wishlist successfully!");
    dispatch(addWishList(product));
    // Update productservices tb with count

    const res = await fetch(`https://ghmarkets.net/test/ghmarketconnect/?ghw=processpayment&svg=wishlist`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        prodcode,
        name,
        price,
        description,
        picture,
        model,
        sellercode,
        servicetype,
      }),
    });
    const response = await res.json();
    //console.log(response);
  };

  const addToCompareList = (
    prodcode,
    name,
    price,
    description,
    picture,
    model,
    sellercode,
    servicetype
  ) => {
    const product = {
      prodcode,
      name,
      price,
      description,
      picture,
      model,
      sellercode,
      servicetype,
    };
    // dispatch an add action
    notify("Product added successfully to your compare list!");
    dispatch(addToCompare(product));
  };
  const handleSelectVariations = (variations) => {
    setSelectedVariations(variations);
  };

  const descQty = () => {
    setQuantity(quantity > 1 ? quantity - 1 : 1);
    //console.log(`Qty: ${quantity}`);
  };

  const incrQty = () => {
    setQuantity(quantity + 1);
    //console.log(`Qty: ${quantity}`);
  };

  return (
    <div>
      <Header />

      <main>
        <div className="breadcrumb-area-two">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="breadcrumb-wrap">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <NavLink to="/">Home</NavLink>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        shop single
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shop-details-area pt-80 pb-90">
          <div className="container">
            <div className="row">
              <div className="col-xl-5 col-lg-6">
                <div className="shop-details-img-wrap">
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane show active"
                      id="nav-item-one"
                      role="tabpanel"
                      aria-labelledby="nav-item-one-tab"
                    >
                      <div className="shop-details-img">
                        {specItem.previewpicture || specItem.picture !== "" ? (
                          <img src={specItem.previewpicture || specItem.picture} alt="img" />
                        ) : (
                          <ProdImageSkeleton />
                        )}
                      </div>
                    </div>

                    <div
                      className="tab-pane"
                      id="nav-item-two"
                      role="tabpanel"
                      aria-labelledby="nav-item-two-tab"
                    >
                      <div className="shop-details-img">
                        <img src={shop_nav_img01} alt="img" />
                      </div>
                    </div>
                    <div
                      className="tab-pane"
                      id="nav-item-three"
                      role="tabpanel"
                      aria-labelledby="nav-item-three-tab"
                    >
                      <div className="shop-details-img">
                        <img src={shop_nav_img02} alt="img" />
                      </div>
                    </div>
                    <div
                      className="tab-pane"
                      id="nav-item-four"
                      role="tabpanel"
                      aria-labelledby="nav-item-four-tab"
                    >
                      <div className="shop-details-img">
                        <img src={shop_nav_img03} alt="img" />
                      </div>
                    </div>
                  </div>
                </div>

                <Suspense fallback={<div>Loading...</div>}>
                  {/* Lazy load the Productsimages component */}
                  <Productsimages productcode={prodcode} />
                </Suspense>
              </div>
              <div className="col-xl-5 col-lg-6 col-md-8">
                <div className="shop-details-content">
                  {/* <span>
                    <i className="fa-solid fa-check"></i>In Stock
                  </span> */}
                  <h2 className="title">{specItem.name}</h2>

                  <div className="shop-details-price">
                    <h2 className="title">{specItem.price ? `Gh₵${specItem.price}` : ""}</h2>
                    {/* <h4 className="stock-status">- IN Stock</h4> */}
                  </div>
                  <p>{specItem.description}</p>
                  <div className="shop-details-bottom">
                    {specItem.servicetype === "1" ? (
                      <>
                        <ul>
                          <li
                            className="sd-sku"
                            style={{ paddingBottom: "20px" }}
                          >
                            <span className="title">Region/State :</span>
                            {sellerregion || ""}
                          </li>
                        </ul>
                      </>
                    ) : (
                      <ProductVariations
                        onSelectVariations={handleSelectVariations}
                      />
                    )}
                  </div>
                  <div className="shop-details-quantity">
                    {specItem.servicetype === "1" ? (
                      ""
                    ) : (
                      <>
                        {/* <span>Quantity :</span> */}
                        <div className="cart-plus-minus">
                          {/* <input
                            type="text"
                            defaultValue="1"
                            // onChange={() => setQuantity(e.target.value)}
                          /> */}
                          <div className="cart-plus-minus">
                            <input type="text" value={quantity} minLength={1} />
                            <div
                              className="dec qtybutton"
                              onClick={() => descQty()}
                            >
                              -
                            </div>
                            <div
                              className="inc qtybutton"
                              onClick={() => incrQty()}
                            >
                              +
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {specItem.servicetype === "1" ? (
                      specItem.price ? (
                        <NavLink
                          to={`/service/request/${prodcode}`}
                          className="wishlist-btn"
                        >
                          <i className="fa-solid fa-gear"></i>Request Service
                        </NavLink>
                      ) : (
                        <NavLink
                          to={`/service/request/${prodcode}`}
                          className="wishlist-btn"
                        >
                          <i className="fa-solid fa-gear"></i>Request Service
                        </NavLink>
                      )
                    ) : (
                      <div className="shop-details-quantity mt-30 ">
                        <NavLink
                          //   to="shop-details.html"
                          className="wishlist-btn px-5"
                          onClick={() =>
                            addToCart(
                              prodcode,
                              specItem.name,
                              specItem.price,
                              specItem.description,
                              specItem.previewpicture || specItem.picture,
                              specItem.model,
                              specItem.sellercode,
                              specItem.servicetype,
                              quantity,
                              specItem.categoryCode,
                              specItem.subcategoryCode,
                              specItem.sellername,
                              selectedVariations
                            )
                          }
                        >
                          <i className="fa-solid fa-cart-arrow-down  "></i> Add
                          to Cart
                        </NavLink>
                        {/* <NavLink to="shop-details.html" className="cart-btn">
                          Buy now
                        </NavLink> */}
                      </div>
                    )}
                  </div>
                  <div style={{ marginTop: "-35px", marginBottom: "25px" }}>
                    {" "}
                    <Rating prodcode={prodcode} />
                  </div>
                  <div className="shop-details-Wishlist">
                    <ul>
                      <li>
                        <NavLink
                          onClick={() =>
                            addToWishList(
                              prodcode,
                              specItem.name,
                              specItem.price,
                              specItem.description,
                              specItem.previewpicture || specItem.picture,
                              specItem.model,
                              specItem.sellercode,
                              specItem.servicetype
                            )
                          }
                        >
                          <i className="fa-regular fa-heart"></i>Add to Wishlist
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() =>
                            addToCompareList(
                              prodcode,
                              specItem.name,
                              specItem.price,
                              specItem.description,
                              specItem.previewpicture || specItem.picture,
                              specItem.model,
                              specItem.sellercode,
                              specItem.servicetype
                            )
                          }
                        >
                          <i className="fa-solid fa-chart-column"></i>Compare
                        </NavLink>
                      </li>
                      {/* <li className="sd-share">
                        <span className="title">Share Now :</span>
                        <NavLink to="#">
                          <i className="fab fa-facebook-f"></i>
                        </NavLink>
                        <NavLink to="#">
                          <i className="fab fa-twitter"></i>
                        </NavLink>
                        <NavLink to="#">
                          <i className="fab fa-linkedin-in"></i>
                        </NavLink>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-12 col-md-4">
                <div className="recommended-item-wrap">
                  <div className="recommended scroll">
                    <span>Recommended For You :</span>
                    {recommendedProducts ? (
                      recommendedProducts.map((prod) => {
                        return (
                          <div className="recommended-item mb-25" key={prod.id}>
                            <div className="thumb">
                              <NavLink to={`/shopdetail/${prod.code}`}>
                                <img
                                  src={prod.previewpicture || prod.picture}
                                  alt="img"
                                  style={{ cursor: "pointer" }}
                                />
                              </NavLink>
                            </div>
                            <div className="content">
                              <h6
                                className="title text-center"
                                style={{ cursor: "pointer" }}
                              >
                                <NavLink to={`/shopdetail/${prod.code}`}>
                                  {prod.name}
                                </NavLink>
                              </h6>
                              <h5 className="price text-center">{` ₵${prod.price}`}</h5>
                              <ul>
                                <li className="text-center">
                                  by{" "}
                                  <a href="vendor-profile.html">
                                    {prod.sellername}
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <RecommendedProdsSkeleton />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* <ToastContainer /> */}
    </div>
  );
}

export default Shopdetail;
