import React, { useEffect, useState, useRef, useContext } from "react";
import { NavLink, json, useParams } from "react-router-dom";
import { API_BASE_URL } from "../config/connect";
// import { addWishList } from "../store/wishListSlice";
// import { addToCompare } from "../store/compareSlice";
// import { useDispatch } from "react-redux";
import BidsCountDown from "../components/BidsCountDown";
import shop_nav_img01 from "../assets/img/product/shop_nav_img01.jpg";
import shop_nav_img02 from "../assets/img/product/shop_nav_img02.jpg";
import shop_nav_img03 from "../assets/img/product/shop_nav_img03.jpg";
// import shop_nav_img04 from "../assets/img/product/shop_nav_img04.jpg";
import { Button, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import Header from "../components/Header";
import Productsimages from "../components/Productsimages";
import RecommendedProds from "../components/RecommendedProds";
import ProdImageSkeleton from "../components/ProdImageSkeleton";
// import ProgressBar from "../components/ProgressBar";
import validator from "validator";
import ReCAPTCHA from "react-google-recaptcha";
import { useProductData } from "../components/ProductsContextApi";
import RecommendedProdsSkeleton from "../components/RecommendedProdsSkeleton";
// import 'bootstrap/dist/css/bootstrap.min.css';

function BidsTenderDetails() {
  const recaptcha = useRef();
  const notify = (msg) => toast.success(msg);
  const notifyError = (msg) => toast.error(msg);
  const { prodcode } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const [loading, setLoading] = useState(false);
  const [confirmEmail, setConfirmEmail] = useState(""); // Separate state for confirm email
  const [message, setMessage] = useState("");
  const [emailconfirmmsg, setConfirmMessage] = useState("");
  const [validinput, setValidinput] = useState(true);
  const [biddingList, setBiddingList] = useState([]);
  const [username, setUsername] = useState("");
  const [show, setShow] = useState(false);
  const Swal = require("sweetalert2");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const allprods = useProductData().allProductData;
  const specItem = allprods?.find((product) => product.code === prodcode);

  const recommendedProducts = allprods?.filter((product) => product.categorycode == specItem.categorycode && product.subcategorycode == specItem.subcategorycode && product.brand != specItem.brand && product.bidstender == '1')



  //diplay products on page render
  useEffect(() => {
    // getAllProducts();

    getBiddingList();
    // Check if user is already logged in and get username
    const storedUsername = localStorage.getItem("username");
    if (storedUsername) {
      setUsername(storedUsername);
      getCustomerDetails();
    }
  }, [prodcode, biddingList]);

  const [data, setData] = useState({
    code: "",
    sellercode: specItem.sellercode,
    itemcode: specItem.code,
    biddingprice: "",
    clientemail: "",
    clientfirstname: "",
    clientlastname: "",
    phone_number: "",
    address: "",
  });

  // Update handleEmail
  const handleEmail = (event) => {
    const emailValidity = event.target.value;
    setConfirmEmail(emailValidity); // Update confirm email state
    if (data.clientemail !== emailValidity) {
      setConfirmMessage("Email mismatch!");
      setValidinput(false);
    } else {
      setConfirmMessage("");
      setValidinput(true);
    }
  };

  // const handleEmail = (event) => {
  //   let emailvalidity = event.target.value;
  //   if (actualmail != emailvalidity) {
  //     setConfirmMessage("Email validation....Not yet validated");
  //     setValidinput(false);
  //   } else {
  //     setConfirmMessage("");
  //     setValidinput(true);
  //   }
  // };

  const handleInput = (event) => {
    const { name, value } = event.target;

    if (name == "clientemail") {
      // setConfirmEmail(value);
      // console.log(value);
      if (!validator.isEmail(value)) {
        setMessage("Please, enter a valid email!");
        setValidinput(false);
      } else {
        setMessage("");
      }
    }

    // setInputs((inputs) => ({ ...inputs, [name]: value }));
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //axios code to make a post request
  const postData = () => {
    setLoading(true);
    var payload = {
      ...data,
    };

    axios
      .post(
        `https://ghmarkets.net/test/ghmarketconnect/?ghw=productservices&svg=biddingproducts`,
        JSON.stringify(payload)
      )
      .then((res) => {
        // console.log(res);
        // console.log();

        if (res.data.trim() == "email does not exist") {
          Swal.fire({
            title: "Confirm Email!",
            text: "To successfully send a bid request you will need to confirm that your email is valid. We have sent you a mail to your address to confirm!",
            icon: "success"
          });
        } else {
          Swal.fire({
            title: "Sucess!",
            text: "Bidding request successfully sent!",
            icon: "success"
          });
        }


        // notify("");
        setLoading(false);
        setShow(false)
        document.getElementById("clientfirstname").value = "";
        document.getElementById("clientlastname").value = "";
        document.getElementById("clientemail").value = "";
        document.getElementById("biddingprice").value = "";
        document.getElementById("confirmemail").value = "";
        document.getElementById("phone_number").value = "";
        document.getElementById("address").value = "";
      })
      .catch((error) => {
        console.log(error);
        console.log("some error")
      });
  };


  //check if the user is already logged in and populate details in the fields
  const getBiddingList = () => {
    fetch(
      `https://ghmarkets.net/test/ghmarketconnect/?ghw=productservices&svg=biddingperitem&prcode=${prodcode}`
    )
      .then((response) => response.json())
      .then((json) => {
        // console.log(json["results"]);
        setIsLoading(false)
        if (json["results"] && json["results"].length > 0) {
          setBiddingList(json["results"]);
          // console.log(biddingList)
        }
      });
  };


  // Utility function to truncate email
  function truncateText(email) {
    const [username, domain] = email.split('@');
    const usernameWithoutDot = username.substring(0, 6);
    const newEmail = `${usernameWithoutDot}...@${domain}`;
    return newEmail;
  }

  //check if the user is already logged in and populate details in the fields
  const getCustomerDetails = () => {
    fetch(
      `https://ghmarkets.net/test/ghmarketconnect/?ghw=login&svg=get_cust_details&email=${localStorage.getItem("username")}`
    )
      .then((response) => response.json())
      .then((json) => {
        // console.log(json["results"][0]);
        if (json["results"] && json["results"].length > 0) {
          setData((prevData) => ({
            ...prevData,
            clientfirstname: json["results"][0].firstname,
            clientlastname: json["results"][0].lastname,
            phone_number: json["results"][0].phonenumber,
            clientemail: json["results"][0].email,
            address: json["results"][0].address,
            // confirmemail: json['results'][0].email,
          }));
          setConfirmEmail(json["results"][0].email);
        }
      });
  };
  const handlePostRequest = () => {
    if (
      !data.clientfirstname ||
      !data.clientlastname ||
      !data.phone_number ||
      !data.clientemail ||
      !data.biddingprice ||
      !data.address
    ) {
      notifyError("Please fill out all fields!");
      return;
    }


    if (validinput) {
      const captchaValue = recaptcha.current.getValue();
      if (captchaValue) {
        postData();
      } else {
        notifyError("Please verify the reCAPTCHA!");
      }
    } else {
      notifyError("There is an error in your form.");
    }

    //  console.log(...data);
  };

  const date = new Date(specItem.closingdate);
  // console.log(date + "aaaaaaaaaaaaaaa")

  const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  const month =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  const year = date.getFullYear();
  const hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  const minutes =
    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();

  return (
    <>
      <div>
        <Header />

        <main>
          <div className="breadcrumb-area-two">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="breadcrumb-wrap">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <NavLink to="/">Home</NavLink>
                        </li>

                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          bids & tender
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="shop-details-area pt-80 pb-90">
            <div className="container">
              <div className="row">
                <div className="col-xl-5 col-lg-6">
                  <div className="shop-details-img-wrap">
                    <div className="tab-content" id="nav-tabContent">
                      <div
                        className="tab-pane show active"
                        id="nav-item-one"
                        role="tabpanel"
                        aria-labelledby="nav-item-one-tab"
                      >
                        <div className="shop-details-img">
                          {specItem.previewpicture || specItem.picture !== "" ? (
                            <img src={specItem.previewpicture || specItem.picture} alt="img" />
                          ) : (
                            <ProdImageSkeleton />
                          )}
                        </div>
                      </div>

                      <div
                        className="tab-pane"
                        id="nav-item-two"
                        role="tabpanel"
                        aria-labelledby="nav-item-two-tab"
                      >
                        <div className="shop-details-img">
                          <img src={shop_nav_img01} alt="img" />
                        </div>
                      </div>
                      <div
                        className="tab-pane"
                        id="nav-item-three"
                        role="tabpanel"
                        aria-labelledby="nav-item-three-tab"
                      >
                        <div className="shop-details-img">
                          <img src={shop_nav_img02} alt="img" />
                        </div>
                      </div>
                      <div
                        className="tab-pane"
                        id="nav-item-four"
                        role="tabpanel"
                        aria-labelledby="nav-item-four-tab"
                      >
                        <div className="shop-details-img">
                          <img src={shop_nav_img03} alt="img" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <Productsimages productcode={prodcode} />
                </div>
                <div className="col-xl-5 col-lg-6 col-md-8">
                  <div className="shop-details-content">
                    <span>
                      <i className=" ">
                        {" "}
                        <BidsCountDown targetDate={specItem.closingDate} />
                      </i>{" "}
                    </span>
                    <h2 className="title">{specItem.name}</h2>

                    <div className="shop-details-price">
                      <h2 className="title">
                        Ghs {specItem.price.toLocaleString() || ""}
                      </h2>
                      <h4 className="stock-status">- IN Stock </h4>
                    </div>
                    <p>{specItem.description}</p>
                    <div className="table-container">
                      <table className="responsive-table">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Email</th>
                            <th scope="col">Amount</th>
                          </tr>
                        </thead>
                        <tbody>


                          {isLoading ? (
                            <tr >
                              <td colSpan="3" style={{ textAlign: "center" }} >
                                <div className="lds-ellipsis">
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                </div>
                              </td>
                            </tr>
                          ) : biddingList.length > 0 ? (
                            biddingList
                            .sort((a, b) => b.biddingprice - a.biddingprice) // Sort by bidding price in descending order
                            .slice(0, 5) // Slice to limit to first 5 elements after sorting
                            .map((bid, i) => (
                                <tr key={i}>
                                  <td>{i + 1}</td>
                                  <td>{truncateText(bid.clientemail)}</td>
                                  <td>{bid.biddingprice.toLocaleString()}</td>
                                </tr>
                              ))
                          ) : (
                            <tr>
                              <td colSpan="3" style={{ textAlign: "center" }}>No data available</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="shop-details-quantity"
                      style={{ display: "flex", justifyContent: "flex-start", marginTop: "10px" }}>
                      {/* Button trigger modal */}
                      <NavLink to="#" onClick={handleShow}>
                        Bid Now
                      </NavLink>

                      {/* Modal */}
                      <Modal show={show} onHide={handleClose} centered size="lg">
                        <Modal.Header closeButton>
                          <Modal.Title>Bidding Request</Modal.Title>
                        </Modal.Header>
                        <Modal.Body> <form>
                          <div class="row">
                            <div class="col-md-6">
                              <label class="form-check-label" for="gridCheck">First Name</label>

                              <input type="text" class="form-control" placeholder="Enter your firstname"
                                id="clientfirstname"
                                name="clientfirstname"
                                onChange={(event) => handleInput(event)}
                                value={data.clientfirstname}
                                reequired
                              />
                            </div>
                            <div class="col-md-6">
                              <label class="form-check-label" for="gridCheck">Last Name</label>

                              <input type="text" class="form-control" placeholder="Last name" id="clientlastname"
                                name="clientlastname"
                                onChange={(event) => handleInput(event)}
                                reequired
                                value={data.clientlastname} />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <label class="form-check-label" for="gridCheck">Residential Address</label>

                              <input type="text" class="form-control" placeholder="Address" id="address"
                                name="address"
                                onChange={(event) => handleInput(event)}
                                value={data.address}
                                reequired />
                            </div>
                            <div class="col-md-6">
                              <label class="form-check-label" for="gridCheck">Phone number</label>

                              <input name="phone_number"
                                type="text"
                                className="form-control"
                                id="phone_number"
                                placeholder="Phone Number"
                                onChange={(event) => handleInput(event)}
                                value={data.phone_number}
                                reequire />
                            </div>
                          </div> <div class="row">
                            <div class="col-md-6">
                              <label class="form-check-label" for="gridCheck">Email <span style={{ color: "red" }}>
                                {" "}
                                {message}{" "}
                              </span>{" "}</label>
                              <input class="form-control" placeholder="Email" type="email"
                                id="clientemail"
                                name="clientemail"
                                onChange={(event) => handleInput(event)}
                                value={data.clientemail}
                                reequired />
                            </div>
                            <div class="col-md-6">
                              <label class="form-check-label" for="gridCheck">Confirm Email   <span style={{ color: "red" }}>
                                {" "}
                                {emailconfirmmsg}
                              </span></label>

                              <input class="form-control" placeholder="" type="email"
                                id="confirmemail"
                                name="confirmemail"
                                onChange={handleEmail}
                                value={confirmEmail} />
                            </div>
                          </div> <div class="row">
                            <div class="col-md-12">
                              <label class="form-check-label" for="gridCheck">Bidding Amount</label>

                              <input type="text"
                                className="form-control mt-2"
                                autoComplete="off"
                                id="biddingprice"
                                name="biddingprice"
                                onChange={(event) => handleInput(event)}
                                reequired />
                            </div>
                          </div>
                          <ReCAPTCHA
                            ref={recaptcha}
                            sitekey={process.env.REACT_APP_SITE_KEY}
                          />
                        </form></Modal.Body>
                        <Modal.Footer className="shop-details-quantity" >
                          {/* <Button variant="secondary" onClick={handleClose}>
                            Close
                          </Button> */}
                          <NavLink onClick={handlePostRequest} >
                            {!loading ? (
                              <>
                                <i className="fa-solid fa-cart-arrow-down"></i>{" "}
                                Submit
                              </>
                            ) : (
                              <>
                                <i className="fas fa-circle-notch fa-spin"></i>{" "}
                                Please Wait
                              </>
                            )}{" "}
                          </NavLink>
                        </Modal.Footer>
                      </Modal>
                    </div>
                    {/* <form>
                      <div className=" ">
                        <div>
                          <div className="shop-details-quantity">
                            <div className="col-md-5">
                              <span>First Name:</span>
                              <input
                                className="form-control col-md-3"
                                type="text"

                              // onChange={handleChange}
                              />
                            </div>

                            <div className="col-md-5 ps-2">
                              <span>Last Name:</span>
                              <input
                                className="form-control col-md-3"
                                type="text"
                                placeholder="Enter your lastname"


                              // onChange={handleChange}
                              />{" "}
                            </div>
                          </div>

                          <div className="shop-details-quantity">
                            <div className="col-md-5">
                              <span>
                                Email:{" "}

                              </span>
                              <input
                                className="form-control"
                                type="email"
                                placeholder="Enter your email address"
                                id="clientemail"
                                name="clientemail"
                                onChange={(event) => handleInput(event)}
                                value={data.clientemail}
                                reequired
                              />
                            </div>

                            <div className="col-md-5 ps-2">
                              <span>
                                {" "}
                                Confirm Email:{" "}

                              </span>
                              <input
                                className="form-control"

                              />
                            </div>
                          </div>
                        </div>

                        <div className="shop-details-quantity">
                          <div className="col-md-10">
                            <span>Residential Address</span>
                            <input
                              className="form-control col-md-3"
                              type="text"
                              placeholder="Enter your address"


                            // onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className="shop-details-quantity">
                          <div className="col-md-5">
                            <label for="inputEmail4">Phone Number</label>
                            <input
                              name="phone_number"
                              type="text"
                              className="form-control"
                              id="phone_number"
                              placeholder="Phone Number"
                              onChange={(event) => handleInput(event)}
                              value={data.phone_number}
                              reequired
                            />
                          </div>

                          <div className="col-md-5 ps-2">
                            <span>Bidding Amount :</span>
                            <div className="cart-plus-minus">
                              <input
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                id="biddingprice"
                                name="biddingprice"
                                onChange={(event) => handleInput(event)}
                                reequired
                              />
                            </div>
                          </div>
                        </div>

                        <div className="shop-details-quantity">
                       
                          <NavLink
                            //   to="shop-details.html"
                            className="wishlist-btn mt-4"
                            onClick={handlePostRequest}
                          >
                           
                          </NavLink>
                        </div>
                      </div>
                    </form> */}
                    {/* <div className="shop-details-Wishlist">
                      <ul>
                        <li>
                          <NavLink  onClick={() =>
                            addToWishList(
                              prodcode,
                              name,
                              price,
                              description,
                              picture,
                              model,
                              sellercode,
                              servicetype
                            )
                          }>
                            
                            <i className="fa-regular fa-heart"></i>Add to
                            Wishlist
                          </NavLink>
                        </li>
                        <li>
                          <NavLink                onClick={() =>
                            addToCompareList(
                              prodcode,
                              name,
                              price,
                              description,
                              picture,
                              model,
                              sellercode,
                              servicetype
                            )
                          }>
                            <i className="fa-solid fa-chart-column"></i>Compare
                          </NavLink>
                        </li>
                      </ul>
                    </div> */}
                    <div className="shop-details-bottom">
                      <ul>
                        <li className="sd-category">
                          <span className="title">Categories :</span>
                          <NavLink to="">{specItem.model}</NavLink>
                        </li>
                        <li className="sd-sku">
                          <span className="title">Closing Date: </span>{" "}
                          {`${day || ""}-${month || ""}-${year || ""} at ${hours || ""}:${minutes || ""}`}
                        </li>
                        {/*<li className="sd-share">
                          <span className="title">Share Now :</span>
                          <NavLink to="#">
                            <i className="fab fa-facebook-f"></i>
                          </NavLink>
                          <NavLink to="#">
                            <i className="fab fa-twitter"></i>
                          </NavLink>
                          <NavLink to="#">
                            <i className="fab fa-linkedin-in"></i>
                          </NavLink>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-12 col-md-4">
                  <div className="recommended-item-wrap">
                    <div className="recommended scroll">
                      <span>Recommended For You :</span>
                      {recommendedProducts ? (
                        recommendedProducts.map((prod) => {
                          return (
                            <div
                              className="recommended-item mb-25"
                              key={prod.id}
                            >
                              <div className="thumb">
                                <NavLink to={`/productslist/bidstenderdetails/${prod.code}`}>
                                  <img
                                    src={prod.previewpicture || prod.picture}
                                    alt="img"
                                    style={{ cursor: "pointer" }}
                                  />
                                </NavLink>
                              </div>
                              <div className="content">
                                <h6
                                  className="title text-center"
                                  style={{ cursor: "pointer" }}
                                >
                                  <NavLink to={`/productslist/bidstenderdetails/${prod.code}`}>
                                    {prod.name}
                                  </NavLink>
                                </h6>
                                <h5 className="price text-center">{` ₵${prod.price}`}</h5>
                                <ul>
                                  <li className="text-center">
                                    by{" "}
                                    <a href="vendor-profile.html">
                                      {prod.sellername}
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <RecommendedProdsSkeleton />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <ToastContainer />
    </>
  );
}

export default BidsTenderDetails;
