import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { API_BASE_URL } from "../config/connect";
import Leftmenu from "../components/Leftmenu";
import Navbar from "../components/Navbar";
import Livemarket from "./Livemarket";
import Topbar from "../components/Topbar";
import Topsearch from "../components/Topsearch";
// import w_logo from "../assets/img/logo/w_logo.png";
// import africamarkets_logo from "../assets/img/logo/orangeafricamarketstrip.jpg";
import megamenu_banner from "../assets/img/images/megamenu_banner.jpg";
import Leftmenusubcat from "../components/Leftmenusubcat";
//import "../assets/css/header.css"; // Import CSS file for styles
import Navbarr from "../components/Navbarr";
// import logouticon from '../assets/img/images/power-off.png'
// import ordersicon from '../assets/img/images/choices.png'
// import reseticon from '../assets/img/images/reset-password.png'
// import accountsicon from '../assets/img/images/user.png'
// import messsageicon from '../assets/img/images/email.png'
// import dropdownicon from "../assets/img/images/dropdownarrow.png";


function Header() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // const [firstname, setFirstName] = useState("");

  const getAllCategories = () => {
    fetch(`https://ghmarkets.net/test/ghmarketconnect/?ghw=categories&svg=list`)
      .then(response => response.json())
      .then(json => {
        setCategories(json['results']);
      });
  };

  useEffect(() => {
    getAllCategories();
    // Check if user is already logged in
    const username = localStorage.getItem("username");
    if (username) {
      setIsLoggedIn(true);
      // setFirstName(localStorage.getItem("firstname"));
    }
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setIsMenuVisible(!isMenuVisible); // Toggle menu visibility
    // document.body.classList.toggle("blur"); // Add blur effect to body
  };

  const closeMenu = () => {
    setIsDropdownOpen(false);
    setIsMenuVisible(false); // Hide menu
    // document.body.classList.remove("blur"); // Remove blur effect
  };
    // handle logout events from the local storage
    const handleLogout = () => {
      setIsLoggedIn(false);
      localStorage.removeItem("username");
      localStorage.removeItem("firstname");
    };
  

  return (
    <div>
      <header>
        <Topbar />
        <Topsearch />
       
        <div id="sticky-header" className="menu-area">
           
     
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="mobile-nav-toggler" onClick={toggleDropdown}>
                  <i className="fas fa-bars"></i>
                </div>

                <div>
                  <nav className="menu-nav">
                    <div className="logo d-none">
                      <NavLink to="/">
                        {/* <img src={africamarkets_logo} alt="africamarkets_logo" /> */}
                        <div className="logoimgsm"></div>
                      </NavLink>
                    </div>

                    <Leftmenu />
                    <Navbar />
                    <Livemarket />
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="menu-smallarea d-lg-none" >
     <Navbarr  />
               </div>
        <div className={`side-menu ${isMenuVisible ? "open" : ""}`}>
          <div className="menu-content">
            <ul className="category-menu">
              <li className="add-megamenu">
                <NavLink to="">
                  <i className="fa-sharp fa-solid fa-shop" style={{ padding: '10px', color: '#F6921E' }}></i><span style={{ color: 'black', padding: '10px' }}>All Categories</span><hr style={{height: '2px', color : '#F6921E'}}/>
                </NavLink>
                <button className="close-btn" onClick={closeMenu}>
              <i className="fas fa-times" style ={{color: '#F6921E'}}></i>
            </button>
              </li>
              {categories ? categories.map((post, index) => (
                <li key={index} className="menu-item-has-children">
                  <NavLink to="/">
                    <i className={post.icon} style={{ padding: '10px' , color: 'orange'}} ></i><span style={{ color: 'black', padding: '10px' }}>{post.name}</span>
                  </NavLink>
                  { <ul className="megamenu">
<Leftmenusubcat catcode={post.code} />

<li className="sub-column-item">
{/* <NavLink to="/">Audio & Video</NavLink> */}
<ul>
  <li className="mega-menu-banner">
    <NavLink to="/">
      <img src={megamenu_banner} alt="megamenu_banner" />
    </NavLink>
  </li>
</ul>
</li>
</ul> }

                </li>
              )) : ''}
            </ul>
           
          </div>
          <hr style={{height: '2px', color : '#F6921E'}}/>
          {isLoggedIn ? (
                      // <li class="header-sine-in">
                        <div class="dropdowns">
                          {/* <NavLink href="#">
                            <i class="flaticon-user "></i>
                            <p style={{ display: "inline-block" }}>
                              Welcome, <span>{firstname}</span>
                            </p>
                            <img
                              src={dropdownicon}
                              style={{
                                width: "18px",
                                height: "auto",
                                marginLeft: "25px",
                              }}
                              alt="dropdownicon"
                            />
                          </NavLink> */}
                          {/* <div class="dropdown-content"> */}
                            <NavLink to="/account"><i className={'fa fa-user icon-dropdowns'} style={{ color: 'orange',  padding: '5px'}} ></i><span className="caret icon-dropdowns">Account</span></NavLink>
                            <NavLink to="/orders"><i className={'fa fa-shopping-bag icon-dropdowns'} style={{ color: 'orange', padding: '5px'}} ></i><span className="caret icon-dropdowns">Orders</span></NavLink>
                            <NavLink to="/messages"><i className={'fa fa-message icon-dropdowns'} style={{ color: 'orange', padding: '5px'}} ></i><span className="caret icon-dropdowns">My Messages</span></NavLink>
                            <NavLink to="/resetpass"><i className={'fa fa-key icon-dropdowns'} style={{color: 'orange',  padding: '5px'}} ></i><span className="caret icon-dropdowns">Password Reset</span></NavLink>

                            <NavLink to="/login" onClick={handleLogout}><i className={'fa-solid fa-toggle-off icon-dropdowns'}  style={{color: 'orange', padding: '5px'}}>
                              </i><span className="caret icon-dropdowns">Logout</span>
                            </NavLink>
                          {/* </div> */}
                        </div>
                      // </li>
                    ) : (
                      <li class="">
                        <div class="dropdown">
                          <NavLink to="/login">
                            <i class="flaticon-user " style={{ padding: '10px' , color: 'orange'}}></i>{" "}
                            {/* <p style={{ display: "inline-block" }}> */}
                              Customer Login
                            {/* </p> */}
                          </NavLink>
                        </div>
                      </li>
                    )}
        </div>
      </header>
    </div>
  );
}

export default Header;
